@font-face {
  font-family: Redig;
  src: url('Redig-Oblique.eot'); /* IE9 Compat Modes */
  src: url('Redig-Oblique.woff') format('woff'), /* Pretty Modern Browsers */ url('Redig-Oblique.woff2') format('woff2'); /* Super Modern Browsers */
  font-style: oblique;
}
@font-face {
  font-family: Redig;
  src: url('Redig-Regular.eot'); /* IE9 Compat Modes */
  src: url('Redig-Regular.woff') format('woff'), /* Pretty Modern Browsers */ url('Redig-Regular.woff2') format('woff2'); /* Super Modern Browsers */
  font-style: normal;
}
