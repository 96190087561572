.Home {
  @apply lg:pt-0 w-full h-full px-[16px] lg:px-[80px] 2xl:px-[160px];
}
.section {
  @apply pt-[60px] first:pt-0  lg:pb-0 lg:pt-[120px] first:lg:pt-[120px];
}

.LostAstronaut {
  height: min-content;
  height: -webkit-fill-available;
  height: intrinsic;
}
