@tailwind components;
@layer components {
  /* flex justify-center items-center */
  .flex-center-center {
    @apply flex justify-center items-center;
  }

  /* flex items-center */
  .flex-items-center {
    @apply flex items-center;
  }

  /* flex justify-center */
  .flex-justify-center {
    @apply flex justify-center;
  }

  /* Text Component*/
  .g-text-h2 {
    @apply font-bsd font-bold text-center lg:text-start text-48px lg:text-64px leading-[56px] lg:leading-[72px];
  }

  .g-text-h3 {
    @apply font-bsd font-bold text-36px leading-[56px] lg:text-48px lg:leading-[52px];
  }
}
