@font-face {
  font-family: BigShouldersDisplay;
  src: url('./BigShouldersDisplay-Thin.ttf');
  font-style: normal;
  font-weight: 100;
}
@font-face {
  font-family: BigShouldersDisplay;
  src: url('./BigShouldersDisplay-ExtraLight.ttf');
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: BigShouldersDisplay;
  src: url('./BigShouldersDisplay-Light.ttf');
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: BigShouldersDisplay;
  src: url('./BigShouldersDisplay-Regular.ttf');
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: BigShouldersDisplay;
  src: url('./BigShouldersDisplay-Medium.ttf');
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: BigShouldersDisplay;
  src: url('./BigShouldersDisplay-SemiBold.ttf');
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: BigShouldersDisplay;
  src: url('./BigShouldersDisplay-Bold.ttf');
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: BigShouldersDisplay;
  src: url('./BigShouldersDisplay-ExtraBold.ttf');
  font-style: normal;
  font-weight: 800;
}
@font-face {
  font-family: BigShouldersDisplay;
  src: url('./BigShouldersDisplay-Black.ttf');
  font-style: normal;
  font-weight: 900;
}
