@font-face {
  font-family: SFPro;
  src: url('SF-Pro-Text-Thin.otf');
  font-style: normal;
  font-weight: 100;
}
@font-face {
  font-family: SFPro;
  src: url('SF-Pro-Text-ThinItalic.otf');
  font-style: italic;
  font-weight: 100;
}
@font-face {
  font-family: SFPro;
  src: url('SF-Pro-Text-Ultralight.otf');
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: SFPro;
  src: url('SF-Pro-Text-UltralightItalic.otf');
  font-style: italic;
  font-weight: 200;
}
@font-face {
  font-family: SFPro;
  src: url('SF-Pro-Text-Light.otf');
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: SFPro;
  src: url('SF-Pro-Text-LightItalic.otf');
  font-style: italic;
  font-weight: 300;
}
@font-face {
  font-family: SFPro;
  src: url('SF-Pro-Text-Regular.otf');
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: SFPro;
  src: url('SF-Pro-Text-RegularItalic.otf');
  font-style: italic;
  font-weight: 400;
}
@font-face {
  font-family: SFPro;
  src: url('SF-Pro-Text-Medium.otf');
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: SFPro;
  src: url('SF-Pro-Text-MediumItalic.otf');
  font-style: italic;
  font-weight: 500;
}
@font-face {
  font-family: SFPro;
  src: url('SF-Pro-Text-Semibold.otf');
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: SFPro;
  src: url('SF-Pro-Text-SemiboldItalic.otf');
  font-style: italic;
  font-weight: 600;
}
@font-face {
  font-family: SFPro;
  src: url('SF-Pro-Text-Bold.otf');
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: SFPro;
  src: url('SF-Pro-Text-BoldItalic.otf');
  font-style: italic;
  font-weight: 700;
}
@font-face {
  font-family: SFPro;
  src: url('SF-Pro-Text-Black.otf');
  font-style: normal;
  font-weight: 800;
}
@font-face {
  font-family: SFPro;
  src: url('SF-Pro-Text-BlackItalic.otf');
  font-style: italic;
  font-weight: 800;
}
@font-face {
  font-family: SFPro;
  src: url('SF-Pro-Text-Heavy.otf');
  font-style: normal;
  font-weight: 900;
}
@font-face {
  font-family: SFPro;
  src: url('SF-Pro-Text-HeavyItalic.otf');
  font-style: italic;
  font-weight: 900;
}
