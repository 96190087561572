.textHeader {
  @apply flex items-center  font-bsd font-bold text-[#777E90] cursor-pointer text-32px capitalize;
}

.gradient {
  background: linear-gradient(108.46deg, #5ee6d0 12.51%, #bfc6ff 51.13%, #ffba69 87.49%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @apply font-bsd font-bold w-full;
}
