.Header {
  @apply w-full h-[72px] lg:h-[100px] flex justify-between items-center px-[20px] z-[100] sticky top-0 bg-aura-black;
}

.MenuMobile {
  @apply absolute -top-2 -right-[20px] h-[100vh] w-[100vw] bg-aura-black;
  @apply flex flex-col px-[20px];
  content: ' ';
}

.Divider {
  @apply relative;
}
.Divider::after {
  @apply absolute w-0 lg:w-[1px] bg-gray-c4 h-full -right-7 top-0;
  content: ' ';
}
