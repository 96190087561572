.Layout {
  @apply flex flex-col justify-between items-center relative scroll-smooth select-none bg-aura-black;
}

.Layout::before {
  @apply absolute left-0 z-0 w-full top-[72px] lg:top-10;
  height: -webkit-fill-available;
  content: '';
  background: url('../../assets/images/main-bg.png') no-repeat;
  background-size: 100%;
}

.main {
  @apply container grow text-white w-full h-full z-10 flex justify-center;
}
