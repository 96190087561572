.GradientButton {
  border: 1px solid transparent;
  background-image: linear-gradient(0deg, #131419, #131419),
    linear-gradient(108.46deg, #5ee6d0 12.51%, #bfc6ff 51.13%, #ffba69 87.49%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  border-radius: 50px;
  padding: 0;
  background-color: transparent;
  min-width: 128px;
  height: 48px;

  @apply flex items-center justify-center text-white font-sfp cursor-pointer;
}
