.Card {
  @apply box-border flex lg:flex-col p-6 lg:p-10 gap-6 w-full lg:min-h-[398px] border-2 border-neutrals-c3;

  box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.1);
  border-radius: 20px;

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 1;
}

.LinkCard {
  @apply flex flex-col justify-between p-4 gap-2 w-full bg-gray-c5 rounded-lg cursor-pointer;
  /* height: 138px; */
}
